import React, { useRef, useEffect, useState, useCallback, useMemo } from 'react';
import { Provider, useDispatch } from 'react-redux';
// import ReactDOM from 'react-dom'; // legasy
import { createRoot } from 'react-dom/client'; // instead
import ForceGraph3D from 'react-force-graph-3d';
import * as THREE from 'three';
import { TextureLoader } from 'three';
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';
import NeonGradientCard from './card/Card';
import { LINE_STATUS_COLORS, NODE_STATUS_COLORS } from 'utils/Constants'
import store from '../store';
import { updateCoordinatesRequest } from '../store/graphSlice';


const extraRenderers = [new CSS2DRenderer()];
const LINK_LENGTH = 80;
const CARD_X_OFFSET = 52;
const CARD_Y_OFFSET = 52;

const USE_AUDIO = false;

const Graph3D = ({
  graphData,
  needSimulation,
  fgRef,
  newConnectionLineRef,
  focusOnNode,
  focusNodeId,
  getConnected,
  lineButtonRef,
  blockAllTargets,
  udateGraphData,
  neuroRef,
}) => {
  const firstTime = useRef(true);
  // const [needSimulation, setNeedSimulation] = useState(true);
  // setNeedSimulationf.current = setNeedSimulation;

  const dispatch = useDispatch();
  const starsRef = useRef();
  const hoveredNodeId = useRef();
  const moveDirection = useRef(new THREE.Vector3());
  let tickCount = 0;

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key.toLowerCase()) {
        case 'w':
        case 'ц':
          moveDirection.current.z = 1; // рух вперед
          break;
        case 's':
        case 'і':
        case 'ы':
          moveDirection.current.z = -1; // рух назад
          break;
        case 'ф':
        case 'a':
          moveDirection.current.x = 1; // рух вліво
          break;
        case 'в':
        case 'd':
          moveDirection.current.x = -1; // рух вправо
          break;
        case 'й':
        case 'q':
          moveDirection.current.y = 1; // рух вниз
          break;
        case 'у':
        case 'e':
          moveDirection.current.y = -1; // рух вгору
          break;
        default:
          break;
      }
    };

    const handleKeyUp = (event) => {
      switch (event.key.toLowerCase()) {
        case 'w':
        case 's':
        case 'ц':
        case 'і':
        case 'ы':
          moveDirection.current.z = 0; // зупиняємо рух по осі Z
          break;
        case 'a':
        case 'd':
        case 'ф':
        case 'в':
          moveDirection.current.x = 0; // зупиняємо рух по осі X
          break;
        case 'q':
        case 'e':
        case 'у':
        case 'й':
          moveDirection.current.y = 0; // зупиняємо рух по осі Y
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    if (fgRef.current)
    {
      graphData.nodes.forEach(node => {
        if (node.__threeObj?.material && !node.__threeObj.material.isUnique) {
          node.__threeObj.material = node.__threeObj.material.clone();
          node.__threeObj.material.isUnique = true; // Позначаємо як унікальний
          node.__threeObj.material.opacity = node.isVeryFar ? 0.7 : 0.3;
        }
      })
      // graphData.nodes.forEach(node => {if (node.__threeObj) node.__threeObj.material = node.__threeObj.material.clone()}) // Клонуємо для можливості індивідуального управління
      // setTimeout(() => {
      //   fgRef.current.d3Force('charge', null);  // Вимкнути силу заряду
      //   fgRef.current.d3Force('link', null);    // Вимкнути силу лінків
      //   fgRef.current.d3Force('center', null);  // Вимкнути силу центрування
      // }, 1500); // Очікування 500 мс на ініціалізацію
      
      const controls = fgRef.current?.controls();
      const camera = fgRef.current?.camera();
      if (!controls || !camera) return;

      focusOnNode.current = (node) => {
        if (!node) return;
        const duration = 1500;
        
        const distance = 600; // Відстань до вузла
        const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z);
      
        const newCameraPos = {
          x: node.x * distRatio,
          y: node.y * distRatio,
          z: node.z * distRatio,
        };
    
        const initialDirection = new THREE.Vector3();
        camera.getWorldDirection(initialDirection);
        const initialUp = camera.up.clone(); 
    
      
        fgRef.current.cameraPosition(
          newCameraPos, // нова позиція камери
          { x: node.x, y: node.y - 50, z: node.z }, // точка, на яку дивиться камера
          duration // тривалість анімації в мс
        );
    
    
        setTimeout(() => {
          const newDirection = new THREE.Vector3();
          camera.getWorldDirection(newDirection);
    
          // Вирівнюємо площину панорамування після фокусу
          const upVector = new THREE.Vector3(0, 1, 0); // Стандартний вектор вгору
          const rightVector = new THREE.Vector3().crossVectors(newDirection, upVector).normalize(); // Вектор праворуч
          const adjustedUp = new THREE.Vector3().crossVectors(rightVector, newDirection).normalize(); // Новий вектор "вгору"
    
          // Інтерполяція між поточним і новим вектором вгору
          const startTime = performance.now();
    
          const animateUpVector = () => {
            const elapsed = performance.now() - startTime;
            const t = Math.min(elapsed / (duration / 3), 1); // Нормалізуємо t до [0, 1]
    
            // Інтерполяція між початковим і кінцевим векторами
            camera.up.lerpVectors(initialUp, adjustedUp, t);
    
            // Оновлюємо камеру
            camera.lookAt(node.x, node.y, node.z);
            controls.update();
    
            // Якщо анімація не завершена, продовжуємо
            if (t < 1) {
              requestAnimationFrame(animateUpVector);
            }
          };

          animateUpVector(); // Запуск анімації
        }, duration+1); // Таймер трохи довший за тривалість анімації
      };

      if (!starsRef.current) {

        const scene = fgRef.current.scene(); // Отримуємо сцену графу
        const starGeometry = new THREE.BufferGeometry();

        // Завантажуємо текстуру розмитого кола для зірок
        const textureLoader = new TextureLoader();
        const blurredCircleTexture = textureLoader.load('https://threejs.org/examples/textures/sprites/circle.png');
        // const starMaterial = new THREE.PointsMaterial({
        //   map: blurredCircleTexture, // Застосовуємо текстуру розмитого кола
        //   vertexColors: true, // Дозволяє використовувати кольори для кожної вершини
        //   color: 0xffffff,
        //   size: 3, // Збільшуємо розмір зірок для більш вираженого ефекту
        //   transparent: true,
        //   opacity: 1,
        //   sizeAttenuation: true,
        //   alphaTest: 0.001 // Забезпечує правильну прозорість текстури
        // });

        const starsVertices = [];
        const starsAlphas = [];
        const starsDirections = [];
        for (let i = 0; i < graphData.nodes.length*10; i++) { // Кількість зірок
          const x = THREE.MathUtils.randFloatSpread(10000) * Math.random();
          const y = THREE.MathUtils.randFloatSpread(10000) * Math.random();
          const z = THREE.MathUtils.randFloatSpread(10000) * Math.random();
          starsVertices.push(x, y, z);

          starsAlphas.push(1.0); // Початкове значення альфа-каналу для кожної зірки

          starsDirections.push(new THREE.Vector3(
            (Math.random() - 0.5) * 0.01,
            (Math.random() - 0.5) * 0.01,
            (Math.random() - 0.5) * 0.01
          ));
        }

        starGeometry.setAttribute('position', new THREE.Float32BufferAttribute(starsVertices, 3));
        starGeometry.setAttribute('alpha', new THREE.Float32BufferAttribute(starsAlphas, 1));

        const starMaterial = new THREE.ShaderMaterial({
          uniforms: {
            pointTexture: { value: blurredCircleTexture },
          },
          vertexShader: `
            varying float vAlpha;
            void main() {
              //float distanceToCamera = -mvPosition.z;
              // * (1.0 / -mvPosition.z); //alpha;
              vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
              gl_PointSize = 1000.0 * (1.0 / -mvPosition.z);
              float alpha = (-mvPosition.z < 300.0) ? (-mvPosition.z / 1000.0) : 0.5;
              vAlpha = alpha;
              gl_Position = projectionMatrix * mvPosition;
            }
          `,
          fragmentShader: `
            uniform sampler2D pointTexture;
            varying float vAlpha;
            void main() {
              gl_FragColor = vec4(1.0, 1.0, 1.0, vAlpha);
              gl_FragColor = gl_FragColor * texture2D(pointTexture, gl_PointCoord);
              // if (gl_FragColor.a < 0.05) discard; // Відсікаємо дуже прозорі пікселі
            }
          `,
          transparent: true,
        });
        
        const stars = new THREE.Points(starGeometry, starMaterial);
        stars.userData.directions = starsDirections; // Зберігаємо напрямки руху як користувацькі дані
        starsRef.current = stars;
        scene.add(stars); // Додаємо зірки безпосередньо до сцени графу
        animateStars(); // Запускаємо анімацію зірок
      }
    
      const animateCamera = () => {
        const moveSpeed = 3;

        const direction = new THREE.Vector3();
        camera.getWorldDirection(direction);

        const right = new THREE.Vector3();
        right.crossVectors(camera.up, direction).normalize(); // Вектор праворуч відносно камери

        const up = new THREE.Vector3();
        up.crossVectors(right, direction).normalize(); // Вектор вгору відносно орієнтації камери

        // Отримуємо вектор напрямку камери
        camera.getWorldDirection(direction);
        right.crossVectors(direction, up).normalize(); // отримуємо правий вектор для камери

        // Переміщуємо камеру і її ціль одночасно
        camera.position.addScaledVector(direction, moveDirection.current.z * moveSpeed); // вперед/назад
        camera.position.addScaledVector(right, moveDirection.current.x * moveSpeed); // вліво/вправо
        camera.position.addScaledVector(up, moveDirection.current.y * moveSpeed); // рух вгору/вниз

        // Зміщуємо також ціль камери
        controls.target.addScaledVector(direction, moveDirection.current.z * moveSpeed);
        controls.target.addScaledVector(right, moveDirection.current.x * moveSpeed);
        controls.target.addScaledVector(up, moveDirection.current.y * moveSpeed);

        controls.update();

        requestAnimationFrame(animateCamera);
      };

      animateCamera();

      fgRef.current.d3Force('link').distance(link => {
        return (
          Math.log((graphData.nodes.find(node => node.id === link.source.id)?.weight || 5) + 1)*5
          + Math.log((graphData.nodes.find(node => node.id === link.target.id)?.weight || 5) +1)
        )*8
      });
      fgRef.current.d3Force('charge').strength(-400);

      // const interval = setInterval(() => {
      //   console.log();
      //   // Оновлення параметра distance для вузлів
      //   if (fgRef.current) {
      //     fgRef.current.d3Force('link').distance(link => {
      //       // Імітація динамічної зміни параметра
      //       return (
      //         Math.log((graphData.nodes.find(node => node.id === link.source.id)?.weight || 5) + 1) * 5
      //         + Math.log((graphData.nodes.find(node => node.id === link.target.id)?.weight || 5) + 1)
      //       ) * 8 * (Math.sin(Date.now() / 1000) + 2); // Імітація зміни значення з часом
      //     });
      //     fgRef.current.d3ReheatSimulation(); // Перезапуск симуляції
      //   }
      // }, 1000);

      // fgRef.current.d3Force('node').opacity(node => node.isFar ? 0.7 : 0.15)

      //const updateLinkDistance = () => {
      //  linkForce.distance(50);
      //};

      controls.mouseButtons.LEFT = THREE.MOUSE.PAN;

      // Обертання за допомогою правої кнопки миші
      controls.mouseButtons.RIGHT = THREE.MOUSE.ROTATE;

      // Зум залишається на середній кнопці/колесі миші
      // controls.mouseButtons.MIDDLE = THREE.MOUSE.PAN;

      controls.panSpeed = 0.06;
      controls.update();
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const audioContextRef = useRef(null);
  useEffect(() => {
    updateCardsAndLines();
    if (USE_AUDIO && fgRef.current) {
      // Створення аудіо-контексту та аналізатора
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    
      // Завантаження аудіо-треку
      const audio = new Audio('/track-0.mp3');
      const source = audioContextRef.current.createMediaElementSource(audio);

      audio.currentTime = audio.duration / 2 || 600;

      // Створення аналізаторів
      const bassAnalyser = audioContextRef.current.createAnalyser();
      const bassDataArray = new Uint8Array(bassAnalyser.frequencyBinCount);
      const bass = audioContextRef.current.createBiquadFilter();
      bass.type = 'lowpass';
      bass.frequency.setValueAtTime(150, audioContextRef.current.currentTime);
      source.connect(bass);
      bass.connect(bassAnalyser);

      const midAnalyser = audioContextRef.current.createAnalyser();
      const midDataArray = new Uint8Array(midAnalyser.frequencyBinCount);
      const highCut = audioContextRef.current.createBiquadFilter();
      highCut.type = 'lowpass';
      highCut.frequency.setValueAtTime(2000, audioContextRef.current.currentTime);
      source.connect(highCut);
      const lowCut = audioContextRef.current.createBiquadFilter();
      lowCut.type = 'highpass';
      lowCut.frequency.setValueAtTime(600, audioContextRef.current.currentTime);
      highCut.connect(lowCut);
      lowCut.connect(midAnalyser);
      
      const highAnalyser = audioContextRef.current.createAnalyser();
      const highDataArray = new Uint8Array(highAnalyser.frequencyBinCount);
      const high = audioContextRef.current.createBiquadFilter();
      high.type = 'highpass';
      high.frequency.setValueAtTime(2000, audioContextRef.current.currentTime);
      source.connect(high);
      high.connect(highAnalyser);
      
      source.connect(audioContextRef.current.destination);
      // Очікування взаємодії користувача
      const userInteractionHandler = () => {
        audio.play().then(() => {
          document.removeEventListener('click', userInteractionHandler);
          document.removeEventListener('keydown', userInteractionHandler);
    
          // Запуск оновлення графа
          function updateGraph() {
            fgRef.current.d3Force('link').distance(link => {
              const node = graphData.nodes.find(node => node.id === link.source.id);
    
              // Реакція вузлів на вузькі частотні діапазони
              if (node.weight > 200) {
                bassAnalyser.getByteFrequencyData(bassDataArray);
                return bassDataArray.reduce((a, b) => a + b, 0) / bassDataArray.length * 100
                + (
                  Math.log((graphData.nodes.find(node => node.id === link.source.id)?.weight || 5) + 1)*5
                  + Math.log((graphData.nodes.find(node => node.id === link.target.id)?.weight || 5) +1)
                )*8;
              } else if (node.weight > 10) {
                midAnalyser.getByteFrequencyData(midDataArray);
                return midDataArray.reduce((a, b) => a + b, 0) / midDataArray.length * 5
                + (
                  Math.log((graphData.nodes.find(node => node.id === link.source.id)?.weight || 5) + 1)*5
                  + Math.log((graphData.nodes.find(node => node.id === link.target.id)?.weight || 5) +1)
                )*8;
              } else {
                highAnalyser.getByteFrequencyData(highDataArray);
                return highDataArray.reduce((a, b) => a + b, 0) / highDataArray.length 
                + (
                  Math.log((graphData.nodes.find(node => node.id === link.source.id)?.weight || 5) + 1)*5
                  + Math.log((graphData.nodes.find(node => node.id === link.target.id)?.weight || 5) +1)
                )*8;
              }
            });
    
            fgRef.current.d3ReheatSimulation();
            requestAnimationFrame(updateGraph);
          }
    
          updateGraph();
        }).catch(error => {
          console.error('Error playing audio:', error);
        });
      };
    
      // Очікування будь-якої взаємодії користувача
      document.addEventListener('click', userInteractionHandler);
      document.addEventListener('keydown', userInteractionHandler);
    
      return () => {
        // Очистка ресурсу
        if (audioContextRef.current) audioContextRef.current.close();
      };
    }
  }, [graphData, fgRef]);

  const updateCardsAndLines = () => {
    const camera = fgRef.current?.camera()
    if (!camera) return;

    requestAnimationFrame(updateCardsAndLines);

    document.querySelectorAll('.node-card').forEach(card => {
      // const card_div = card.parentElement;
      // Рахуємо відстань від камери до кожного вузла
      const node = graphData.nodes.find(n => n.id === card.id);
      if (!node || !node?.__threeObj) return;
      if (!node.__threeObj.material.isUnique) {
        node.__threeObj.material = node.__threeObj.material.clone();
        node.__threeObj.material.isUnique = true; // Позначаємо як унікальний
        node.__threeObj.material.opacity = node.isVeryFar ? 0.7 : 0.3;
      }

      const nodePosition = new THREE.Vector3( node.x, node.y, node.z );
      const distance = camera.position.distanceTo(nodePosition);
      
      // Масштабуємо картки на основі відстані
      const scale = node.isFocused ? Math.max(1, (LINK_LENGTH *3 / distance) * Math.log(node.weight + 10) *0.8) : (LINK_LENGTH *3 / distance) * Math.log(node.weight + 10) *0.8;
      // const trnslate_top = (8500 / distance);
      // const trnslate_left = (8000 / distance);
      const translateTop = CARD_Y_OFFSET * scale;
      const translateLeft = CARD_X_OFFSET * scale;
      card.style.transform = `translate(${translateLeft}%, ${translateTop}%) scale(${scale})`;
      // card.style.transform = `scale(${scale})`;
      // card_div.style.top = `${trnslate_top}px`
      // card_div.style.left = `${trnslate_left}px`
      // const minFocusDistance = 100;
      // const maxFocusDistance = 800;
      // //card_div.style.zIndex = Math.floor(1000 - distance);
      // //const connectedLines = document.querySelectorAll(`[id*="${card.id.split('node-card-')[1]}"]`);
      // console.log(Math.sqrt(node.weight + 1))
      if ( distance < 100 ) {
        card.style.opacity = 0;
      //   // const blurAmount = minFocusDistance - distance;
      //   //card.parentElement.style.filter = `blur(${blurAmount*0.01}px)`
      //   const newOpacity = (distance/minFocusDistance) ** 3;
      //   card.parentElement.style.opacity = newOpacity;
      //   // connectedLines.forEach((line) => {if (newOpacity < line.style.opacity) {line.style.opacity = newOpacity} })
      
      } else if (distance > Math.max(800, 80 * Math.sqrt(node.weight + 1)) ) {
          node.setIsFar(true);

          if(distance > Math.max(1200, 150 * Math.sqrt(node.weight + 1))) {
            node.isVeryFar = true;
            card.classList.add('isVeryFar');
            node.__threeObj.material.opacity = 0.7;
          } else {
            node.isVeryFar = false;
            card.classList.remove('isVeryFar');
            node.__threeObj.material.opacity = 0.3;
          }


      //   const blurAmount = distance - maxFocusDistance;
      //   card.parentElement.style.filter = `blur(${blurAmount*0.01}px)`
      //   card.parentElement.style.opacity = (maxFocusDistance/distance) ** 2;

      } else {
        node.isVeryFar = false;
        card.classList.remove('isVeryFar');
        node.__threeObj.material.opacity = 0.3;

        node.setIsFar(false);
        card.style.opacity = 1;
        //   card.parentElement.style.filter = `blur(0px)`
      //   card.parentElement.style.opacity = 0.95;
      };
    });

    graphData.links?.forEach(link => {
      if (!link.__lineObj?.material) return;
      if (!link.__lineObj.material.isUnique) {
        link.__lineObj.material = link.__lineObj.material.clone();
        link.__lineObj.material.isUnique = true; // Позначаємо як унікальний
      }

      const sourceCard = document.getElementById(link.source.id);
      const targetCard = document.getElementById(link.target.id);
      const sourceNode = graphData.nodes.find(node => node.id === link.source.id);
      const targetNode = graphData.nodes.find(node => node.id === link.target.id);

      // if (sourceNode.title === "Мейл") console.log(sourceNode?.isVeryFar);

      let line = document.getElementById(link.id);

      if (sourceNode && targetNode && !sourceNode?.isVeryFar && !targetNode?.isVeryFar && sourceCard && targetCard){
        const sourceCardTransform = window.getComputedStyle(sourceCard.parentElement).transform;
        const sourceCardPosition = extractTranslationFromMatrix(sourceCardTransform, sourceCard, true);

        const targetCardTransform = window.getComputedStyle(targetCard.parentElement).transform;
        const targetCardPosition = extractTranslationFromMatrix(targetCardTransform, targetCard, false);

        const distance = (sourceCardPosition.width + targetCardPosition.width) / 200;
        
        if (
          (
              sourceCardPosition.x > 0 && sourceCardPosition.x < window.innerWidth
            && sourceCardPosition.y > 0 && sourceCardPosition.y < window.innerHeight
          ) || (
              targetCardPosition.x > 0 && targetCardPosition.x < window.innerWidth
            && targetCardPosition.y > 0 && targetCardPosition.y < window.innerHeight
          )
        ) {
          const linksContainerRect = neuroRef.current.getBoundingClientRect();
            // let lineButton = document.getElementById(`line-button-${lineId}`);          
        
            // console.log(link)
            if (!line) {
              line = document.createElement('div');
              line.className = 'link-line';
              line.style.position = 'absolute';
              line.style.top = '50%';
              line.style.left = '50%';
              line.style.pointerEvents = 'none'; // Щоб лінії не блокували кліки
              line.style.borderRadius = '10px';
              line.style.position = 'fixed';
              line.setAttribute('id', link.id);
              line.setAttribute('data-source-id', link.source.id);
              line.setAttribute('data-target-id', link.target.id);
              neuroRef.current.appendChild(line)
            }
      
            const angle_rad = Math.atan2(targetCardPosition.y - sourceCardPosition.y, targetCardPosition.x - sourceCardPosition.x);
            const angle = angle_rad * (180 / Math.PI);
            line.dataset.x1 = sourceCardPosition.x;
            line.dataset.y1 = sourceCardPosition.y;
            line.dataset.x2 = targetCardPosition.x;
            line.dataset.y2 = targetCardPosition.y;
            line.dataset.angle = angle_rad;
            line.dataset.type = link.type;
            line.dataset.targetId = link.target.id;
            line.dataset.sourceId = link.source.id;

            const height = Math.max(Math.log(sourceNode.weight+10)*distance/1.5, 1);

            line.style.width = `${Math.sqrt((targetCardPosition.x - sourceCardPosition.x) ** 2 + (targetCardPosition.y - sourceCardPosition.y - height/3) ** 2)}px`;
            line.style.borderTop = `${height}px solid ${link.type ? '#99999911' : LINE_STATUS_COLORS[sourceNode.status]}`; // Пунктирна лінія
            // line.style.borderTopStyle = '10 40';
            //line.style.backgroundColor = getColorByStatus(nodes.find(n => n.id === link.source.id).status);
            line.style.height = `${height}px`;
            line.style.transformOrigin = 'left center'; // Точка обертання
            line.style.transform = `translate(${sourceCardPosition.x - linksContainerRect.width / 2}px, ${sourceCardPosition.y - linksContainerRect.height / 2 - height/3}px) rotate(${angle}deg)`;
            // line.filter = `blur(${(parseFloat(sourceCard.parentElement.style.filter.match(/blur\((\d+)px\)/)?.[1]) || 0 + parseFloat(targetCard.parentElement.style.filter.match(/blur\((\d+)px\)/)?.[1]) || 0) / 2}px)`;

            const sourceZindex = parseInt(window.getComputedStyle(sourceCard.parentElement).zIndex, 10);
            const targetZindex = parseInt(window.getComputedStyle(targetCard.parentElement).zIndex, 10);
            if (targetZindex > sourceZindex)
            {
              line.style.zIndex = targetZindex -1;
            } else {
              line.style.zIndex = sourceZindex - 1;
            }

            link.__lineObj.material.opacity = 0;
          } else {
            if (line) line.remove()
            link.__lineObj.material.opacity = link.type ? 0.1 : 0.3;
          }
      } else {
        if (line) line.remove();
        link.__lineObj.material.opacity = link.type ? 0.1 : 0.3;
      }
    });
  }

  const extractTranslationFromMatrix = (matrixString, element, source) => {
    const match = /matrix\([^)]+\)/.exec(matrixString);
    if (match) {
      const values = match[0].slice(7, -1).split(', ');
      const x = parseFloat(values[4]);
      const y = parseFloat(values[5]);
      const parentRect = element.parentElement.getBoundingClientRect();
      const rect = element.getBoundingClientRect();

      let offsetX = parentRect.width / 2;
      let offsetY = parentRect.height / 2 -1;
      // if (!source) {
      //   offsetX += rect.width;
      //   offsetY += rect.height;
      // }

      return { x: x + offsetX, y: y + offsetY, width: rect.width, height: rect.height};
    }
    return { x: 0, y: 0 };
  };

  // Функція для анімації зірок
  const animateStars = () => {
    requestAnimationFrame(animateStars); // Запитуємо наступний кадр анімації

    if (starsRef.current) {
      const positions = starsRef.current.geometry.attributes.position.array;
      // const alphas = starsRef.current.geometry.attributes.alpha.array;
      const directions = starsRef.current.userData.directions;

      for (let i = 0; i < positions.length; i += 3) {
        positions[i] += directions[i / 3].x     * 2; // Оновлення позицій зірок
        positions[i + 1] += directions[i / 3].y * 2;
        positions[i + 2] += directions[i / 3].z * 2;

        // Зміна напрямку руху для створення ефекту мерехтіння

        if (Math.random() > 0.995) {
          directions[i / 3].x *= -1;
        }
        if (Math.random() > 0.995) {
          directions[i / 3].y *= -1;
        }
        if (Math.random() > 0.995) {
          directions[i / 3].z *= -1;
        }
      }
      starsRef.current.geometry.attributes.position.needsUpdate = true; // Оновлюємо геометрію для рендерингу
    }
  };

  // const rootId = 0;

  // const nodesById = useMemo(() => {
  //   const nodesById = Object.fromEntries(graphData.nodes.map(node => [node.id, node]));

  //   // link parent/children
  //   graphData.nodes.forEach(node => {
  //     node.collapsed = node.id !== rootId;
  //     node.childLinks = [];
  //   });
  //   graphData.links.forEach(link => nodesById[link.source].childLinks.push(link));

  //   return nodesById;
  // }, [graphData]);

  // const getPrunedTree = useCallback(() => {
  //   const visibleNodes = [];
  //   const visibleLinks = [];
  //   (function traverseTree(node = nodesById[rootId]) {
  //     visibleNodes.push(node);
  //     if (node.collapsed) return;
  //     visibleLinks.push(...node.childLinks);
  //     node.childLinks
  //       .map(link => ((typeof link.target) === 'object') ? link.target : nodesById[link.target]) // get child node
  //       .forEach(traverseTree);
  //   })();

  //   return { nodes: visibleNodes, links: visibleLinks };
  // }, [nodesById]);

  // const [prunedTree, setPrunedTree] = useState(getPrunedTree());

  // const handleNodeCollapse = useCallback(node => {
  //   node.collapsed = !node.collapsed; // toggle collapse state
  //   setPrunedTree(getPrunedTree())
  // }, []);

  return (
    <ForceGraph3D
    graphData={graphData}
    onEngineTick={() => {
      tickCount += 1;  // Збільшуємо кількість тіків

      if (tickCount === 5 && firstTime.current && graphData.nodes.length > 2) {
        firstTime.current = false;
        fgRef.current.zoomToFit(1000);
        
        if (graphData.nodes.length > 10) setTimeout(() => {
          const duration = 800; // Тривалість анімації для кожної ноди в мілісекундах
          let minScreenX = Infinity;
          const nodesWithScreenPos = graphData.nodes.map(node => {
            const card = document.getElementById(node.id);
            if (!card) return null;
        
            // Отримуємо межі елемента
            const rect = card.getBoundingClientRect();
            const screenX = rect.left + rect.width / 2
            if (screenX < minScreenX) minScreenX = screenX;
            return {
              node,
              screenX: screenX, // Центр по X
            };
          }).filter(Boolean); // Фільтруємо ноди без `cssObject`
          // nodesWithScreenPos.sort((a, b) => a.screenX - b.screenX);
          nodesWithScreenPos.forEach((item, index) => {
            setTimeout(() => {
              // Застосування анімації до cssObject ноди
              const card = document.getElementById(item.node.id);
              if (card) {
                card.classList.add('importantView');
                // Повернення до початкового стану
                setTimeout(() => {
                  card.classList.remove('importantView');
                }, duration);
              }
            }, item.screenX - minScreenX); // Затримка для створення хвилі
          });
        }, 800);
      }

      if (tickCount === 20) {
        if (graphData.nodes.length > 2) {
          focusOnNode.current(graphData.nodes.find(node => node.id === focusNodeId.current));
        }
        focusNodeId.current = null;
      }
      
    }}

    nodeColor={node => {
      return NODE_STATUS_COLORS[node.status].slice(0,7)
    }}
    onNodeDrag={() => needSimulation.current = true}
    onNodeDragEnd={node => {
      if (node.pinned){
        node.fx = node.x;
        node.fy = node.y;
        node.fz = node.z;
      } else {
        node.fx = null;
        node.fy = null;
        node.fz = null;
      }
    }}
    onEngineStop={() => {
      if (tickCount) {
        needSimulation.current = false;
        dispatch(updateCoordinatesRequest({
          nodes: graphData.nodes.map(node => ({
            id: node.id,
            x: node.x,
            y: node.y,
            z: node.z,
          }
          ))
        }))
      }
      tickCount = 0;
    }}
    //key={CryptoJS.SHA256(graphData.nodes.map(n => n.id).join('')+graphData.links.map(l => `${l.source}-${l.target}`).join(''))}
    ref={fgRef}
    // forceEngine="d3"
    // warmupTicks={tickCount}
    // cooldownTicks={needSimulation.current ? 200 : 0}
    // cooldownTicks={100}
    // d3AlphaMin={0.1}
    // d3AlphaDecay={0.01}
    // d3VelocityDecay={USE_AUDIO ? 0.001 : 0.02} 
    // dagMode='zout'

    backgroundColor="#11111100"
    linkDirectionalParticles={8}
    // linkDirectionalParticles={(link) => {
    //if (link.__lineObj) {
    //  const startPos = link.__lineObj.geometry.attributes.position.array.slice(0, 3);
    //  const endPos = link.__lineObj.geometry.attributes.position.array.slice(3, 6);
  
    //  const dx = startPos[0] - endPos[0];
    //  const dy = startPos[1] - endPos[1];
    //  const dz = startPos[2] - endPos[2];
    //  const distance = Math.sqrt(dx * dx + dy * dy + dz * dz);
    //  console.log(distance)
    //  // Визначаємо кількість частинок на основі фактичної довжини лінка
    //  const particlesCount = Math.max(8, Math.round(distance / 5)); // Налаштуйте цей множник для бажаного ефекту
    //  return particlesCount;
    //}
    //  return link.type ? 0 : 8; // За замовчуванням, якщо координати не визначені
    // }}
    linkDirectionalParticleColor={link => {
      const sourceNode = graphData.nodes.find(node => node.id === link.source);
      if (sourceNode) {
        return link.type !== 0 ? '#808080' : LINE_STATUS_COLORS[sourceNode?.status];
      }
      return '#808080';
    }}
    linkDirectionalParticleWidth={link => Math.log(graphData.nodes.find(node => node.id === (link.source.id ? link.source.id : link.source))?.weight || 4 + 1)/2}
    linkDirectionalParticleSpeed={0.001}
    
    linkWidth={link => {
      return Math.log(graphData.nodes.find(node => node.id === (link.source.id ? link.source.id : link.source))?.weight || 4 + 1)/2
    }}
    // linkLineDash={(d) => {return d.link.type === 0 ? [5, 15] : []}}
    linkOpacity={1}
    // linkColor={(link) => {return link.type !== 0 ? "#ffffff00" : lineStatusColors[graphData.nodes.find(n => n.id === link.source.id)?.status]}}
    linkColor={link => {
      const sourceNode = graphData.nodes.find(node => node.id === (link.source.id ? link.source.id : link.source));
      return link.type ? '#99999901' : LINE_STATUS_COLORS[sourceNode?.status];
    }}
    onLinkClick={link => fgRef.current.emitParticle(link)}
    onNodeClick={node => {focusOnNode.current(node)}}
    onNodeHover={n => {
      if (n) {
        if(!hoveredNodeId.current) {
          const node = graphData.nodes.find(nd => nd.id === n.id);
          hoveredNodeId.current = node.id;

          const connectedIds = new Set(getConnected(hoveredNodeId.current, false).map(e => e.id));
          // console.log(connectedIds);
          document.querySelectorAll('.node-card, .link-line').forEach(element => {
            if (hoveredNodeId.current === element.id) {
              // element.classList.add('importantView');
              // element.classList.add('highlight');
              node.setIsFocused(true);
            } else { 
              if (connectedIds.has(element.id)) {
                element.classList.remove('hide-blur');
                element.classList.add('importantView');
              } else {
                element.classList.add('hide-blur');  // Затемнюємо непов'язані ноди
                element.classList.remove('importantView');
              }
            }
          });
        }
      } else {
        const node = graphData.nodes.find(nd => nd.id === hoveredNodeId.current);
        document.querySelectorAll('.node-card, .link-line, .line-button').forEach(element => {
          if (element.id === hoveredNodeId.current) node.setIsFocused(false);
          else element.classList.remove('hide-blur', 'importantView');
        });
        // const node = graphData.nodes.find(nd => nd.id === hoveredNodeId.current.id);
        hoveredNodeId.current = null;
      }
    }}
    //warmupTicks={20}
    // nodeOpacity={0.3}
    nodeResolution={16}
    nodeRelSize={3}
    nodeVal={node => node.weight}
    //nodeVal={(node) => {return node.title}}
    showNavInfo={false}
    //autoPauseRedraw={false}
    nodeThreeObjectExtend={true}
    extraRenderers={extraRenderers}
    // nodeLabel={node => node.isFar && `<div
    //   style="
    //     zIndex: 100000;
    //     padding: 20px;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //   "
    // >
    //   ${ReactDOMServer.renderToString(
    //   <Provider store={store}>
    //     <NeonGradientCard
    //       fgRef={fgRef}
    //       node={node}
    //       label={true}
    //     />
    //   </Provider>
    // )}
    // </div>`}
    nodeThreeObject={node => {
      const Render = (cont) => {
        // Перевіряємо, чи є корінь React у контейнері
        if (!cont.__reactRoot) {
          cont.__reactRoot = createRoot(cont);
        }
    
        cont.__reactRoot.render(
          <Provider store={store}>
            <NeonGradientCard
              fgRef={fgRef}
              node={node}
              newConnectionLineRef={newConnectionLineRef}
              focusOnNode={focusOnNode}
              getConnected={getConnected}
              neuroRef={neuroRef}
              focusNodeId={focusNodeId}
              needSimulation={needSimulation}
              lineButtonRef={lineButtonRef}
              hoveredNodeId={hoveredNodeId}
              blockAllTargets={blockAllTargets}
            />
          </Provider>
        );
      };

      if (!node.__card) {
        const container = document.createElement('div');
        node.__card = new CSS2DObject(container);
      }

      Render(node.__card.element);
      return node.__card;
    }}
    
  />
  );
};

export default Graph3D;
