import React, { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import classNames from "classnames"; // Correct way to import the classnames package
import { STATUS_COLORS, STATUS_OPTIONS, IS_MOBILE, LINE_STATUS_COLORS, DATE_OPTIONS } from 'utils/Constants'
import { updateNodesRequest } from '../../store/graphSlice';
import { v4 as uuidv4 } from 'uuid';
import { addNodeRequest, addLinkRequest, deleteRequest } from '../../store/graphSlice';
import { color } from "framer-motion";


const NeonGradientCard = React.memo(({
  inStack = false,
  node,
  borderSize = inStack ? 2 : Math.log(node.weight + 10),
  borderRadius = 14,
  neonColors = STATUS_COLORS[node.status] || { firstColor: "#ff00aa", secondColor: "#00FFF1", },
  newConnectionLineRef,
  focusOnNode,
  getConnected,
  neuroRef,
  focusNodeId,
  needSimulation,
  lineButtonRef,
  fgRef,
  label,
  hoveredNodeId,
  blockAllTargets,
  ...props
}) => {
  const [isFar, setIsFar] = useState(false);
  node.setIsFar = setIsFar;
  node.isFar = isFar;
  const [isFocused, setIsFocused] = useState(label)
  node.setIsFocused = setIsFocused;
  node.isFocused = isFocused;
  const graphData = useSelector((state) => state.graph.graphData);
  const dispatch = useDispatch();
  const animationSpeed = useRef(Math.random() + 3);
  const animationDelay = useRef(Math.random() + 2);
  const wasFocused = useRef(false);

  const cardRef = useRef();
  const containerRef = useRef();
  const volumeLabelRef = useRef(null);
  

  const handleNodeFieldChange = useCallback( (field, newValue) => {
    document.querySelectorAll('.node-card, .link-line').forEach(e => e.classList.remove('hide-blur'));

    const changes = [{ id: node.id, [field]: newValue }];
    
    if (field === 'status') {
      const targetNodes = getConnected(node.id, false, true, true, 0);

      targetNodes.forEach((targetNode) => {
        const childNodes = getConnected(targetNode.id, false, true, false, 0);

        const isBlocked = childNodes.some(child => child.id === node.id ? newValue !== 3 : child.status !== 3);
        if (isBlocked) blockAllTargets(targetNode.id);
        else if (targetNode.status !== 0) changes.push({ id: targetNode.id, status: 0 });
      });

    };
    dispatch(updateNodesRequest(changes));

  }, [dispatch, node.id, getConnected] );
    

  const handleMouseEnter = useCallback(() => {
    if (inStack) {
      const connectedIds = new Set(getConnected(node.id, false).map(e => e.id));

      // Затемнюємо всі непов'язані вузли
      document.querySelectorAll('.node-card, .link-line').forEach(element => {
        if (node.id === element.id) element.classList.add('highlight');
        else { 
          if (connectedIds.has(element.id)) {
            element.classList.remove('hide-blur');
            element.classList.add('importantView');
          } else {
            element.classList.add('hide-blur');  // Затемнюємо непов'язані ноди
            element.classList.remove('importantView');
          }
      }
      });
    } else {
      document.querySelectorAll('.node-card, .link-line, .line-button').forEach(element => {
        if (element.id !== node.id) element.classList.add('hide-blur');
      });
    }
  }, []);

  const handleMouseLeave = useCallback(() => {
    document.querySelectorAll('.node-card, .link-line, .line-button, .status-choose-container, .status-info-container, .trash-container').forEach(e => e.classList.remove('hide-blur', 'highlight'));
  }, []);

  useEffect(() => {
    if (cardRef.current) {
      if (wasFocused.current) {
        cardRef.current.style.transition = 'none';
        wasFocused.current = false;
      } else cardRef.current.style.transition = 'opacity 0.2s ease-out';
    }

    if (isFocused) {
      handleMouseEnter();
      wasFocused.current = true;
    }

    // const elements = inStack ? [cardRef.current] : isFocused ? cardRef.current.querySelectorAll('.get-info, .info-buttons-container, .status-choose-container, .status-info-container, .trash-container') : null;

    // if (isFocused || inStack) elements.forEach(getInfoElement => {
    //   getInfoElement.addEventListener('mouseenter', handleMouseEnter);
    //   getInfoElement.addEventListener('mouseleave', handleMouseLeave);
    // });
    // else elements?.forEach(getInfoElement => {
    //   getInfoElement.removeEventListener('mouseenter', handleMouseEnter);
    //   getInfoElement.removeEventListener('mouseleave', handleMouseLeave);
    // });

    return () => {
      if (isFocused) handleMouseLeave();
      // elements?.forEach(getInfoElement => {
      //   getInfoElement.removeEventListener('mouseenter', handleMouseEnter);
      //   getInfoElement.removeEventListener('mouseleave', handleMouseLeave);
      // });
    };

  }, [isFocused]);
  
  const [hoveredVolumeIndex, setHoveredVolumeIndex] = useState(null);

  const handleVolumeMouseMove = (e) => {
    const containerRect = e.currentTarget.getBoundingClientRect();
    const mouseY = e.clientY - containerRect.top;  // Визначаємо позицію миші відносно контейнера
    const barHeight = containerRect.height / 10;  // Висота кожної рисочки
    const indexD = (containerRect.height - mouseY) / barHeight;
    const index = Math.floor(indexD);  // Обчислюємо індекс рисочки на основі позиції миші
    // console.log(index);
    setHoveredVolumeIndex(index);  // Оновлюємо індекс рисочки
    
    if (volumeLabelRef.current) {
      volumeLabelRef.current.style.color = index < 5 ? '#11aa00' : index < 8 ? '#bb9a00' : '#bb0000';
      volumeLabelRef.current.style.opacity = '0.8';
      volumeLabelRef.current.textContent = `${index + 1}`;  // Оновлюємо текст
      volumeLabelRef.current.style.top = `${70-indexD*6.8}%`;  // Оновлюємо вертикальну позицію тексту
      volumeLabelRef.current.style.right = '-38%'
      volumeLabelRef.current.style.textShadow = ` 0 0 6px ${volumeLabelRef.current.style.color}`;
      // volumeLabelRef.current.style.left = `${containerRect.right + 10}px`;  // Оновлюємо горизонтальну позицію тексту
  }
};

const handleVolumeMouseLeave = () => {
  setHoveredVolumeIndex(null);  // Скидаємо індекс при знятті наведення
  if (volumeLabelRef.current) volumeLabelRef.current.style.opacity = '0';
};

const addNewNode = () => {
  const newNode = {
      id: uuidv4(),
      x: node.x, // Якщо є цільовий вузол, використовуємо його координати
      y: node.y - 20, // Зміщуємо по осі Y для нових вузлів
      z: node.z, // Якщо цільового вузла немає, використовуємо нульові координати
  }
  const connected = getConnected(node.id, false, false, false); // nodeId, elements = true, nodes = null, source = null, type = null
  const type = connected.length === 0 || connected.some(link => link.type === 0) ? 0 : 1;
  if (type === 0) {
    blockAllTargets(node.id);
  }

  focusNodeId.current = newNode.id;
  dispatch(addNodeRequest(newNode));
  dispatch(addLinkRequest({id: uuidv4(), target: node.id, source: newNode.id, type: type }));
  // needSimulation.current = true;
  fgRef.current.refresh();
};

const deleteNode = () => {
  document.querySelectorAll('.node-card, .link-line').forEach(e => e.classList?.remove('hide-blur'));
  
  const linksToDelete = getConnected(node.id, false, false);
  dispatch(deleteRequest({nodes: [node.id], links: linksToDelete.map(l => l.id)}));

  setTimeout(() => {
    linksToDelete.forEach(link => {
      document.getElementById(link.id)?.remove();
    });
  }, 100); // Налаштуйте час затримки, якщо потрібно

  const changes = [];
  const targetNodes = getConnected(node.id, false, true, true);

  targetNodes.forEach(targetNode => {
    if (targetNode.status === 2) {
      const childNodes = getConnected(targetNode.id, false, true, false);
      const isBlocked = childNodes.some(child => child.id !== node.id && child.status !== 3);
      if (!isBlocked) changes.push({ id: targetNode.id, status: 0 });
    }
  });
  
  dispatch(updateNodesRequest(changes));
  needSimulation.current = true;
}

  const [newLinkStart, setNewLinkStart] = useState(null);
  const avaiableCards = useRef(null);
  const newLinkEnd = useRef(null);

  useEffect(() => {
    if (newLinkStart) {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    } else {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    }
  }, [newLinkStart]);

  const handleNewLink = (e, source) => {
    const unavaiableNodeIds = new Set(getConnected(node.id, false, true).map(e => e.id));
    document.querySelectorAll('.link-line').forEach(link => link.classList.add('hide-blur'))
    avaiableCards.current = Array.from(document.querySelectorAll('.node-card'))
      .filter(card => {
        if (card.id === node.id) return false;
        else if (unavaiableNodeIds.has(card.id)) {
          card.classList.add('hide-blur');
          return false;
        }
        const rect = card.getBoundingClientRect();

        // Перевірка видимості картки (часткової чи повної)
        return (
          rect.top < window.innerHeight &&
          rect.bottom > 0 &&
          rect.left < window.innerWidth &&
          rect.right > 0
        );
      })
      .map(card => ({
        id: card.id,        // Зберігаємо id картки
        rect: card.getBoundingClientRect(), // Зберігаємо координати картки
        zIndex: parseInt(card.zIndex, 10)
    }));

    neuroRef.current.classList.add('new-link-establishing')
    const cardRect = cardRef.current.getBoundingClientRect();

    if (source) {
      newConnectionLineRef.current.setAttribute('stroke', LINE_STATUS_COLORS[node.status]);
      newConnectionLineRef.current.classList.add('new-connection-line');
      newConnectionLineRef.current.classList.remove('new-connection-line-reverse');
    } else {
      newConnectionLineRef.current.classList.add('new-connection-line-reverse');
      newConnectionLineRef.current.classList.remove('new-connection-line');
    }
    
    //newConnectionLineRef.current.style.width = cardRect.width / 50;
    newConnectionLineRef.current.setAttribute('stroke-width', cardRect.width / 50);
    newConnectionLineRef.current.setAttribute('x1', cardRect.left);
    newConnectionLineRef.current.setAttribute('y1', cardRect.top);
    newConnectionLineRef.current.setAttribute('x2', e.clientX);
    newConnectionLineRef.current.setAttribute('y2', e.clientY);
    newConnectionLineRef.current.style.display = 'flex';

    setNewLinkStart({source});
    lineButtonRef.current.style.opacity = 0;
  }


  const handleMouseMove = (e) => {
      if (newLinkStart) {
          const topCard =  avaiableCards.current.filter(card => {
              return (
                  e.clientX >= card.rect.left && e.clientX <= card.rect.right &&
                  e.clientY >= card.rect.top && e.clientY <= card.rect.bottom
              );
              }).sort((a, b) => {
              return b.zIndex - a.zIndex;
              })[0] || null;
            if (topCard) {
              newLinkEnd.current = topCard.id;
              newConnectionLineRef.current.setAttribute('x2', topCard.rect.left);
              newConnectionLineRef.current.setAttribute('y2', topCard.rect.top);
              if (!newLinkStart.source) {newConnectionLineRef.current.setAttribute('stroke', LINE_STATUS_COLORS[graphData.nodes.find(n => n.id === topCard.id).status]);}
            } else {
                newLinkEnd.current = null;
                newConnectionLineRef.current.setAttribute('x2', e.clientX);
                newConnectionLineRef.current.setAttribute('y2', e.clientY);
                if (!newLinkStart.source) {newConnectionLineRef.current.setAttribute('stroke', '#bbbbbb44');}
            }
      }
  };

  const handleMouseUp = () => {
    handleMouseLeave();

    neuroRef.current.classList.remove('new-link-establishing')
    setNewLinkStart(null);

    newConnectionLineRef.current.style.display = 'none';

    if (newLinkEnd.current) {
      needSimulation.current = true;
      
      const connectedStart = getConnected(node.id, false, false, false);
      const connectedEnd = getConnected(newLinkEnd.current, false, false, false);
      const type = connectedStart.length === 0 ||
        connectedStart.some(link => link.type === 0) ||
        connectedStart.length === 0 ||
        connectedEnd.some(link => link.type === 0)
        ? 0 : 1;

      if (newLinkStart.source) {
        dispatch(addLinkRequest({
          id: uuidv4(),
          target: newLinkEnd.current,
          source: node.id,
          type: type,
        }));

        if (type === 0 && node.status !== 3 && graphData.nodes.find(n => n.id === newLinkEnd.current).status !== 2) {
          blockAllTargets(newLinkEnd.current);
        }
      } else {
        dispatch(addLinkRequest({
          id: uuidv4(),
          target: node.id,
          source: newLinkEnd.current,
          type: type,
        }));

        if (type === 0 && graphData.nodes.find(n => n.id === newLinkEnd.current).status !== 3 && node.status !== 2) {
          blockAllTargets(node.id);
        }
      }
    }
  };

  const handleUnfocus = (e) => {
    e.stopPropagation();
    if (e.target.tagName === 'CANVAS') {
      setIsFocused(false);
      neuroRef.current.removeEventListener('click', handleUnfocus);
    }
  }

  useEffect(() => {
      if (isFocused) {
          // Додаємо обробник події лише при фокусуванні
          neuroRef?.current.addEventListener('click', handleUnfocus);
          if (cardRef.current) cardRef.current.classList.add('importantView');
      } else {
        neuroRef?.current.removeEventListener('click', handleUnfocus);
        if (cardRef.current) cardRef.current.classList.add('importantView');
      }

      return () => {
          // Завжди видаляємо обробник при знятті фокусу або при розмонтуванні
          neuroRef?.current.removeEventListener('click', handleUnfocus);
      };
  }, [isFocused]);

  return (
    isFar && !inStack && !isFocused ? 
    <div
      key={node.id}
      id={node.id}
      className='node-card isfar'
      ref={cardRef}
      style={{
        "--border-size": `${borderSize/2}px`,
        "--border-radius": `${borderRadius*2}px`,
        "--neon-first-color": neonColors.firstColor,
        "--neon-second-color": neonColors.secondColor,
        "--card-content-radius": `${borderRadius*2}px`,
        "--pseudo-element-background-image": `linear-gradient(-155deg, ${neonColors.firstColor}, ${neonColors.secondColor})`,
        "--after-blur": '30px',
        "--animation-speed": `${animationSpeed.current}s`,
        "--animation-delay": `-${animationDelay.current}s`,
        padding: borderSize,
        pointerEvents: 'none',
        maxWidth: '250px',
        opacity: 1,
        }}
        onMouseEnter={e => {
          if (!hoveredNodeId.current) hoveredNodeId.current = node.id;

          setIsFocused(true);
        }}
    >
      <h1
        className="text-center"
        ref={containerRef}
        style={{
          width: '100%',
          height: '100%',
          fontSize: 20,
          lineHeight: 1,
          fontWeight: 500,
          zIndex: 2,
          opacity: 0.8,
          padding: '12px 15px',
          borderRadius:`0 ${borderRadius*2 - borderSize}px ${borderRadius*2 - borderSize}px ${borderRadius*2 - borderSize}px`,
          // border: `${borderSize}px solid ${nodeStatusColors[node.status].slice(0, 7)}`,
          background: '#000000',
          // border: `2px solid  ${nodeStatusColors[node.status].slice(0,7)}`,
          // textShadow: `0 0 5px black, 0 0 20px ${nodeStatusColors[node.status]}`,
          color: node.title ? 'white' : 'grey',
          transition: 'width 1s ease-out, height 1s ease-out, translate 1s ease-out',
        }}
    >
      {node.title ? node.title : "Untitled"}
    </h1>
    </div> :
    !isFocused && !inStack ? 
    <div
      key={node.id}
      ref={containerRef}
      id={node.id}
      className={classNames('node-card relative z-10 size-full')}
      style={{
        "--border-size": `${borderSize}px`,
        "--border-radius": `${borderRadius}px`,
        "--neon-first-color": neonColors.firstColor,
        "--neon-second-color": neonColors.secondColor,
        "--card-content-radius": `${borderRadius - borderSize}px`,
        "--pseudo-element-background-image": `linear-gradient(-155deg, ${neonColors.firstColor}, ${neonColors.secondColor})`,
        "--pseudo-element-width": `${150 + borderSize * 2}px`,
        "--pseudo-element-height": `${85 + borderSize * 2}px`,
        "--after-blur": IS_MOBILE || inStack ? '0px' : '30px',
        "--animation-speed": `${animationSpeed.current}s`,
        "--animation-delay": `-${animationDelay.current}s`,
        // animation: 'fadeIn 0.5s ease forwards',
        pointerEvents: 'auto'
      }}
      onMouseMove={(e) => {
        if (!hoveredNodeId.current) hoveredNodeId.current = node.id;
        setIsFocused(true);
      }}
    >
      <div
        className={classNames(
            "relative size-full min-h-[inherit] bg-gray-100",
            "dark:bg-neutral-900",
            "card-container"
          )}
          style={{
            backgroundColor: '#0a0a0ae0',
          }}
      >
        <h1
          data-full-text={node.title}
          className="interactive font-bold text-left absolute top-3 left-4 max-w-28 card-title"
          style={{
            fontSize: 16,
            cursor: 'text',
            zIndex: 2,
            minHeight: '1em',
            minWidth: '4em',
            color: node.title ? 'white' : 'grey',
            lineHeight: 1,
          }}
        >
          {node.title ? node.title : "Untitled"}

        </h1>
        {/* <div className="absolute top-2 left-1 cursor-move drag">
          <img
            src="/drag.svg"
            alt="Drag Icon"
            className="w-4 h-4 opacity-50"
            />
        </div> */}
        {/* <div className='connector-line-a' style={{ backgroundColor: nodeStatusColors[node.id] }} ></div> */}
        {/* <div className='connector-line-b' style={{ backgroundColor: nodeStatusColors[node.id] }} ></div> */}
        
      </div>
    </div>
    : // In stock or Focused
    <div
      key={node.id}
      ref={containerRef}
      id={`${inStack || label  ? 'stack-' : ''}${node.id}`}
      style={{
        "--border-size": `${borderSize}px`,
        "--border-radius": `${borderRadius}px`,
        "--neon-first-color": neonColors.firstColor,
        "--neon-second-color": neonColors.secondColor,
        "--card-content-radius": `${borderRadius - borderSize}px`,
        "--pseudo-element-background-image": `linear-gradient(-155deg, ${neonColors.firstColor}, ${neonColors.secondColor})`,
        "--pseudo-element-width": `${150 + borderSize * 2}px`,
        "--pseudo-element-height": `${85 + borderSize * 2}px`,
        "--after-blur": IS_MOBILE || inStack ? '0px' : '30px',
        "--animation-speed": `${animationSpeed.current}s`,
        "--animation-delay": `-${animationDelay.current}s`,
        pointerEvents: 'auto',
        opacity: 1,
      }}
      className={classNames(
        `${inStack || label ? 'stack-card' : 'node-card focused'} relative size-full rounded-[var(--border-radius)]`
      )}
      onWheel={e => {
        if (!inStack) {
          const { x, y, z } = fgRef.current.cameraPosition();
          const zoomFactor = 1 + e.deltaY * 0.002;
          fgRef.current.cameraPosition(
            { x: x * zoomFactor, y: y * zoomFactor, z: z * zoomFactor },
            fgRef.current.controls().target, // Центруємо камеру на графі
            100 // Тривалість анімації
          );
        }
      }}
      onMouseLeave={() => {
        handleMouseLeave();
        setIsFocused(false);
        if (!inStack) {
          hoveredNodeId.current = null;
        }
      }}
      onMouseEnter={(e) => {
        if (!inStack){
          if (!hoveredNodeId.current) hoveredNodeId.current = node.id;
          setIsFocused(true);
        }
      }}
      {...props}
    >
      <div
        ref={cardRef}
        id={`card-${node.id}`}
        onClick={inStack ? ()=>focusOnNode.current(node) : null}
        onDoubleClick={!inStack ? ()=>focusOnNode.current(node) : null}
        className={classNames(
          "relative size-full min-h-[inherit] rounded-[var(--card-content-radius)] bg-gray-100",
          "dark:bg-neutral-900",
          `${inStack || label ? 'stack-' : ''}card-container`
        )}
        style={{
          backgroundColor: '#0a0a0ae0',
        }}
      >
        <h1
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();  // Щоб уникнути перенесення рядка
              e.target.blur();
              //handleEnterPress(e.target.innerText.trim());  // Викликаємо функцію для обробки натискання Enter
            }
            e.stopPropagation();
          }}  // Prevent keydown propagation
          onKeyUp={(e) => {e.stopPropagation()}}      // Prevent keyup propagation
          onBlur={(e) => {
            e.target.textContent = e.target.textContent.replace(/\u200B/g, "");
            if (!e.target.textContent.trim()) {
              e.target.textContent = 'Untitled';  // Повертається псевдотекст, якщо поле залишилось порожнім
              e.target.style.color = 'grey';
            } else {
              e.target.style.color = 'white';
              handleNodeFieldChange('title', e.target.textContent.trim());  // Оновлює значення при втраті фокусу
            }
          }}
          // onInput={e =>
          //   console.log(e.target.textContent)
          // }
          onFocus={(e) => {
            e.stopPropagation();
            if (e.target.style.color === 'grey') {
              e.target.textContent = "\u200B";
            }
            e.target.style.color = 'white';
          }}
          contentEditable
          suppressContentEditableWarning
          data-full-text={node.title}
          className="interactive font-bold text-left absolute top-3 left-4 max-w-28 card-title"
          style={{
            fontSize: 16,
            cursor: 'text',
            zIndex: 2,
            minHeight: '1em',
            minWidth: '4em',
            color: node.title ? 'white' : 'grey',
            lineHeight: 1,
          }}
        >
          {node.title ? node.title : "Untitled"}

        </h1>
        {inStack || label ?
        <></>
        : 
        <>
          <button className="get-info interactive absolute rounded-full bg-black text-white w-8 h-5"
            style={{
              fontSize: 14,
              fontWeight: 500,
              fontFamily: 'helvetica',
              }}
          >
              <i className="fas fa-tasks-alt"></i>
          </button>
          <div className="connect-buttons-container absolute">
            <button className="connect-target interactive absolute rounded-full bg-black text-white w-3 h-5"
              onMouseDown={(e) => handleNewLink(e, true)}
              style={{
                fontSize: 8,
                fontWeight: 500,
                fontFamily: 'helvetica',
                }}
            >
                <i className="fas fa-level-down" style={{transform: 'translateY(0.5px) rotate(180deg)'}}></i>
                
            </button>

            <button className="connect-source interactive absolute rounded-full bg-black text-white w-3 h-5"
              onMouseDown={(e) => handleNewLink(e, false)}
              style={{
                fontSize: 8,
                fontWeight: 500,
                fontFamily: 'helvetica',
                }}
            >
                <i className="fas fa-level-down" style={{transform: 'translate(1.5px, 1px)'}}></i>
            </button>
          </div>

          <div className="absolute info-buttons-container">
            <button className="absolute rounded-full bg-black text-red w-6 h-6 delete-button"
              onClick={deleteNode}
            >
              <i className="fas fa-trash-alt" style={{ color: '#ff2222' }}></i>
            </button>

            <button className="absolute rounded-full bg-black text-white w-6 h-6 share-button"
              onClick={deleteNode}
            >
              <i className="fas fa-users-gear"></i>
            </button>
          </div>

          {/* Іконка для перетягування картки */}
          {/* <div className="absolute top-2 left-1 cursor-move drag">
            <img
              src="/drag.svg"
              alt="Drag Icon"
              className="w-4 h-4 opacity-50"
              />
          </div> */}

          {/* Статус картки */}
          <div className="absolute status-info-container">
            <div className="absolute status-info"
            style={{            
              color: neonColors.secondColor,
              background: `linear-gradient(15deg, ${neonColors.firstColor}22, ${neonColors.secondColor}22)`,
              borderColor: `${neonColors.firstColor}55`,
              fontSize: 14,
              }}
            >
              {STATUS_OPTIONS[node.status]}
            </div>
          </div>

         {/* Контейнер для вибору статусу */}
         {node.status !== 2 && ( 
          <div className="absolute status-choose-container">
            {Object.keys(STATUS_OPTIONS)
              .filter(key => key !== '2' && Number(key) !== node.status) // Виключаємо поточний статус
              .map(key => (
                <button
                  key={key}
                  className="status-choose-button"
                  onClick={() => handleNodeFieldChange('status', Number(key))}
                  style={{
                    // border: `1px solid ${statusColors[option.value].firstColor}11`,
                    background: `linear-gradient(105deg, ${STATUS_COLORS[key].firstColor}22, ${STATUS_COLORS[key].secondColor}22)`,
                    color: STATUS_COLORS[key].secondColor,
                    fontSize: 14,
                  }}
                >
                  {STATUS_OPTIONS[key]}
                </button>
              ))}
          </div>
         )}

          {/* Created time */}
          <div className="absolute created-info"
          style={{
              color: neonColors.secondColor,
              background: `linear-gradient(35deg, ${neonColors.firstColor}44, ${neonColors.secondColor}44)`,
              borderColor: `${neonColors.firstColor}55`,
              fontSize: 14,
            }}
          >
            <strong>Created:</strong> {new Date(node.created_time).toLocaleDateString(navigator.language, DATE_OPTIONS).replaceAll('/', '.').replace(',', '')}
          </div>

          {/* Edited time */}
          <div className="absolute edited-info"
          style={{
              color: neonColors.secondColor,
              background: `linear-gradient(35deg, ${neonColors.firstColor}44, ${neonColors.secondColor}44)`,
              borderColor: `${neonColors.firstColor}55`,
              fontSize: 14,
            }}
          >
            <strong>Edited:</strong> {new Date(node.last_edited_time).toLocaleDateString(navigator.language, DATE_OPTIONS).replaceAll('/', '.').replace(',', '')}
          </div>

          {/* Due time 
          <div className="absolute due-info"
          style={{
              color: neonColors.secondColor,
              background: `linear-gradient(145deg, ${neonColors.firstColor}44, ${neonColors.secondColor}44)`,
              borderColor: `${neonColors.firstColor}55`,
              fontSize: 14,
            }}
          >
            {node.last_edited_time !== '-' ? (
              <>
                <strong>Due:</strong> {new Date(node.last_edited_time).toLocaleDateString(navigator.language, DATE_OPTIONS).replaceAll('/', '.').replace(',', '').replace(' 00:00', '')}
              </>
            ) : (
              <>
                No <strong>due date</strong>
              </>
            )}
          </div> */}

        {/* Кнопка для створення нової картки */}
        <button className="interactive add-button absolute rounded-full bg-black text-white w-9 h-5"
          onClick={() => addNewNode(node)}
          style={{
            fontSize: 15,
          }}
        >
          +<i className="fas fa-level-down"></i>
        </button>
        </>}
        {label && <>
          {/* Статус картки */}
          <div className="absolute status-info-container">
            <div className="absolute status-info"
            style={{            
              color: neonColors.secondColor,
              background: `linear-gradient(15deg, ${neonColors.firstColor}22, ${neonColors.secondColor}22)`,
              borderColor: `${neonColors.firstColor}55`,
              fontSize: 14,
              }}
            >
              {STATUS_OPTIONS[node.status]}
            </div>
          </div>
        </>}
        {/* Контейнер об'єму */}
        <div className="volume-wrapper"
          onClick={() => handleNodeFieldChange('volume', hoveredVolumeIndex)}
        >
          <div className="absolute volume-container" onMouseMove={handleVolumeMouseMove} onMouseLeave={handleVolumeMouseLeave}>
            {Array.from({ length: 10 }).map((_, index) => {
              return (
                <div
                  key={index}
                  // onClick={() => handleNodeFieldChange('volume', index)}
                  className="volume-bar"
                  style={{
                    backgroundColor: index < 5 ? '#11aa00' : index < 8 ? '#bb9a00' : '#bb0000',
                    opacity: hoveredVolumeIndex !== null ? (index <= hoveredVolumeIndex ? 0.9 : 0.1) : (index <= node.volume ? 0.6 : 0.1),
                    width: hoveredVolumeIndex !== null ? (hoveredVolumeIndex === index ? '25px' : Math.abs(hoveredVolumeIndex - index) === 1 ? '22px' : '20px') : '10px',
                  }}  // Задаємо колір
                ></div>
              );
            })}
            {/* Динамічний текст праворуч від рисочок */}
            {!inStack && <div ref={volumeLabelRef} className="absolute volume-label">5</div>}
          </div>
        </div>
      </div>
    </div>
  );
});


export default NeonGradientCard;
