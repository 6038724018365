import React, { useRef, useEffect, useState, useCallback } from 'react';
import { createSelector } from 'reselect';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.css';
import { isEqual } from 'lodash';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { addNodeRequest, updateLinkRequest, deleteRequest, updateNodesRequest } from 'store/graphSlice';
import { logout } from 'store/authSlice';
import { useNavigate } from 'react-router-dom';
import store from '../../store';
import NeonGradientCard from '../card/Card';
import Graph3D from '../Graph3D';
import { NODE_PROPS_TO_UPDATE, LINK_PROPS_TO_UPDATE, } from 'utils/Constants'



function getClosestPointOnLine(mouseX, mouseY, x1, y1, x2, y2, angle, offsetPercent = 0.15) {
    const A = mouseX - x1;
    const B = mouseY - y1;
    const C = x2 - x1;
    const D = y2 - y1;

    const dot = A * C + B * D;
    const lenSq = C * C + D * D;
    const param = lenSq !== 0 ? dot / lenSq : -1;

    // const shift = 0.3 * Math.cos(angle) * Math.sqrt(lenSq);
    // const shiftX = Math.cos(angle*Math.PI/180) * 0.1 * Math.sqrt(lenSq);
    // const shiftY = Math.sin(angle*Math.PI/180) * Math.sqrt(lenSq);

    const tMin = Math.max(offsetPercent, Math.sin(angle) / 4 + Math.cos(angle) / 2.9); // Позиція 10% від початку
    const tMax = 1 - Math.max(offsetPercent, - Math.sin(angle) / 4 - Math.cos(angle) / 2.9); // Позиція 90% від початку

    // Обмежуємо param між 10% та 90%
    const clampedParam = Math.max(tMin, Math.min(param, tMax));

    const closestX = Math.min(x1 + clampedParam * C, window.innerWidth - 40);
    const closestY = Math.min(y1 + clampedParam * D + 2, window.innerHeight - 40);

    return { x: closestX, y: closestY, dist: Math.sqrt((mouseX - closestX) ** 2 + (mouseY - closestY) ** 2) };
}

const NeuroSpace = React.memo(() => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // const graphData = useSelector(state => state.graph.graphData);
    // const graphDataRef = useRef(store.getState().graph.graphData);

    const neuroRef = useRef();
    const fgRef = useRef();
    const newConnectionLineRef = useRef();
    const lineButtonRef = useRef();
    const lineDeleteButtonRef = useRef();
    const lineTypeButtonRef = useRef();
    const lineAddNodeButtonRef = useRef();
    const stackRef = useRef(null);
    const stackButtonRef = useRef(null);
    const focusNodeId = useRef(null);
    const closestLine = useRef(null);
    const needSimulation = useRef(true);
    const focusOnNode = useRef(() => () => { console.log('Focus not defined'); });//move to graph3D
    
    const [stackExpanded, setStackExpanded] = useState(false);
    const [clonedGraphData, setClonedGraphData] = useState();

    // Функція для клонування даних
    const cloneGraphData = (newData) => {
        
        const calculateNodeWeight = (nodeId, graphData, visited = new Set()) => {
            // Запобігаємо циклам
            if (visited.has(nodeId)) {
                return 0;
            }
            visited.add(nodeId);
    
            // Знаходимо вузол
            const node = graphData.nodes.find(n => n.id === nodeId);
            if (!node) return 0;
    
            // Початкова вага вузла (може бути надана або за замовчуванням 1)
            let totalWeight = node.volume || 1;
    
            // Знаходимо дочірні вузли
            const childLinks = graphData.links.filter(link => link.target === nodeId);
            for (const link of childLinks) {
                totalWeight += calculateNodeWeight(link.source, graphData, visited);
            }
    
            return totalWeight;
        };        

        return {
            nodes: newData.nodes.map(newNode => {
                // Знайдемо старий вузол, якщо він є, для збереження відсутніх властивостей
                const oldNode = clonedGraphData?.nodes?.find(oldNode => oldNode.id === newNode.id);
                const weight = calculateNodeWeight(newNode.id, newData);
                // Зберігаємо старі пропси, яких немає в новому графі
                return {
                    ...newNode, // Оновлюємо нові дані
                    weight: weight || newNode.volume,
                    x: oldNode?.x || newNode.x, // Залишаємо координати старого вузла
                    y: oldNode?.y || newNode.y, 
                    z: oldNode?.z || newNode.z,
                    vx: oldNode?.vx, // Залишаємо координати старого вузла
                    vy: oldNode?.vy, 
                    vz: oldNode?.vz,
                    __threeObj: oldNode?.__threeObj
                };
            }),

            links: newData.links.map(newLink => {
                // Знайдемо старий лінк, якщо він є
                // const oldLink = prevData?.links?.find(oldLink => oldLink.source === newLink.source && oldLink.target === newLink.target);
                
                // Зберігаємо старі пропси лінків
                return { ...newLink };
            })
        };
    };


    const getConnected = useCallback((nodeId, elements = true, nodes = null, source = null, type = null) => {
        const links = clonedGraphData.links.filter(link => (
            (type === null || link.type === type) &&
            (
                (source === null && (link.target.id === nodeId || link.source.id === nodeId)) || // all directions
                (source === true && link.source.id === nodeId) || // node is source
                (source === false && link.target.id === nodeId) // node is target
            )
        ));
        if (nodes === false) {
            return elements
                ? links.map(link => document.getElementById(link.id)).filter(Boolean)
                : links;
        } else if (nodes === true) {
            return links.map(link => {
                const connectedNode = link.target.id === nodeId
                    ? clonedGraphData.nodes.find(node => node.id === link.source.id)
                    : clonedGraphData.nodes.find(node => node.id === link.target.id);
                return elements
                    ? document.getElementById(connectedNode.id)
                    : connectedNode;
            }).filter(Boolean);
        } else {
            const linkElements = elements ? links.map(link => document.getElementById(link.id)).filter(Boolean) : links;
            const nodeElements = links.map(link => {
                const connectedNode = link.target.id === nodeId
                    ? clonedGraphData.nodes.find(node => node.id === link.source.id)
                    : clonedGraphData.nodes.find(node => node.id === link.target.id);
                return elements
                    ? document.getElementById(connectedNode.id)
                    : connectedNode;
            }).filter(Boolean);

            return [...linkElements, ...nodeElements];
        }
    }, [clonedGraphData]);

    const blockAllTargets = (nodeId, changes = null, visited = new Set()) => {
        if (visited.has(nodeId)) return;
        visited.add(nodeId);

        const firstCall = changes === null;
        if (firstCall) changes = [];
        
        changes.push({ id: nodeId, status: 2 });

        const primaryTargets = getConnected(nodeId, false, true, true, 0); // nodeId, elements = true, nodes = null, source = null, type = null
        primaryTargets.forEach(target => {if (target.status !== 2) blockAllTargets(target.id, changes, visited)});

        if (firstCall) dispatch(updateNodesRequest(changes));
    }

    const stackExpand = () => {
        setStackExpanded(!stackExpanded);
    };

    const isGraphDataEqual = (nextData, prevData) => {
        const filterProps = (obj, props) => 
            props.reduce((filtered, prop) => {
                if (obj.hasOwnProperty(prop)) {
                    filtered[prop] = obj[prop];
                }
                return filtered;
            }, {});
    
        return isEqual(
            nextData.nodes.map(node => filterProps(node, NODE_PROPS_TO_UPDATE)),
            prevData.nodes.map(node => filterProps(node, NODE_PROPS_TO_UPDATE))
        ) && isEqual(
            nextData.links.map(link => filterProps(link, LINK_PROPS_TO_UPDATE)),
            prevData.links.map(link => filterProps(link, LINK_PROPS_TO_UPDATE))
        );
    };

    useEffect(() => {
        setClonedGraphData(cloneGraphData(store.getState().graph.graphData));
    }, []);

    useEffect(() => {
        // Підписка на зміни в Redux store
        const unsubscribe = store.subscribe(() => { if (!isGraphDataEqual(store.getState().graph.graphData, clonedGraphData)) setClonedGraphData(cloneGraphData(store.getState().graph.graphData)); });

        // Очищаємо підписку, коли компонент буде демонтовано
        return () => {
            unsubscribe();
        };
    }, [clonedGraphData]);


    useEffect(() => {
        setClonedGraphData(cloneGraphData(store.getState().graph.graphData));
    }, []);

    // useEffect(() => {
    //     console.log(isGraphDataEqual(clonedGraphData, store.getState().graph.graphData));
    //     if (!clonedGraphData || !isGraphDataEqual(clonedGraphData, store.getState().graph.graphData)) {
    //         setClonedGraphData(cloneGraphData(store.getState().graph.graphData));
    //     }
    // }, [store.getState().graph.graphData]);

    const addNewNode = () => {
        const newNode = { id: uuidv4() };
        focusNodeId.current = newNode.id;
        dispatch(addNodeRequest(newNode));
        needSimulation.current = true;
    };


    const handleOnMouseMove = (e) => {
        if (lineButtonRef.current.classList.contains('hide-blur')) lineButtonRef.current.style.opacity = 0;
        else {
            const mouseX = e.clientX;
            const mouseY = e.clientY;
            let minDist = Infinity;

            const links = document.querySelectorAll('.link-line');

            links.forEach((line) => {
                const x1 = parseFloat(line?.dataset.x1);
                const y1 = parseFloat(line?.dataset.y1);
                const x2 = parseFloat(line?.dataset.x2);
                const y2 = parseFloat(line?.dataset.y2);
                const angle = parseFloat(line?.dataset.angle);

                const { dist } = getClosestPointOnLine(mouseX, mouseY, x1, y1, x2, y2, angle);

                if (dist < minDist) {
                    minDist = dist;
                    closestLine.current = line;  // Оновлюємо найближчу лінію
                }
            });

            if (closestLine.current) {
                const x1 = parseFloat(closestLine.current?.dataset.x1);
                const y1 = parseFloat(closestLine.current?.dataset.y1);
                const x2 = parseFloat(closestLine.current?.dataset.x2);
                const y2 = parseFloat(closestLine.current?.dataset.y2);
                const angle = parseFloat(closestLine.current?.dataset.angle);
                // Обчислюємо найближчу точку на лінії до курсора миші
                const closestPoint = getClosestPointOnLine(mouseX, mouseY, x1, y1, x2, y2, angle);

                // Обмежуємо позицію кнопки між 10% і 90% від лінії
                const pinned = lineButtonRef.current.classList.contains('pinned');

                // Встановлюємо нову позицію для кнопки
                if (!pinned) {
                    lineButtonRef.current.style.opacity = closestPoint.dist < 70 ? `${closestPoint.dist > 35 ? 35 / closestPoint.dist * 0.99 : 0.99}` : '0';
                    lineButtonRef.current.style.top = `${closestPoint.y}px`;
                    lineButtonRef.current.style.left = `${closestPoint.x}px`;
                    lineButtonRef.current.id = `line-button-${closestLine.current.id}`;
                    lineButtonRef.current.style.backgroundColor = closestLine.current.style.borderTopColor;
                    
                    const size = Math.max(parseFloat(closestLine.current.style.width)/18, 20);
                    lineButtonRef.current.style.fontSize = `${size * 0.6}px`;
                    lineButtonRef.current.style.height = `${size}px`;
                    lineButtonRef.current.style.width = `${size}px`;
                    lineButtonRef.current.style.zIndex = parseFloat(closestLine.current.style.zIndex) + 1;
                }

                if (closestPoint.dist >= 100) {
                    closestLine.current = null;
                    lineButtonRef.current.style.opacity = 0;
                    lineDeleteButtonRef.current.classList.remove('bounce-in');
                    lineTypeButtonRef.current.classList.remove('bounce-in');
                    lineAddNodeButtonRef.current.classList.remove('bounce-in');
                    lineButtonRef.current.classList.remove('pinned');
                } else if (!pinned){
                    const shift = 0.5;
                    const plusAngle = angle+shift;
                    const typeAngle = angle-shift;
                    const deleteAngle = angle+Math.PI;
                    lineTypeButtonRef.current.style.top = `${-130 * Math.abs(Math.cos(typeAngle))}%`;
                    lineTypeButtonRef.current.style.left = `${(Math.cos(typeAngle) < 0 ? -130 : 130) * Math.sin(typeAngle)}%`;
                    lineAddNodeButtonRef.current.style.top = `${-130 * Math.abs(Math.cos(plusAngle))}%`;
                    lineAddNodeButtonRef.current.style.left = `${(Math.cos(plusAngle) < 0 ? -130 : 130) * Math.sin(plusAngle)}%`;
                    lineDeleteButtonRef.current.style.bottom = `${-130 * Math.abs(Math.cos(deleteAngle))}%`;
                    lineDeleteButtonRef.current.style.right = `${(Math.cos(deleteAngle) < 0 ? -130 : 130) * Math.sin(deleteAngle)}%`;
                }
            }
        }
    };

    const splitCurrentLinkAddNode = () => {

    }

    const deleteCurrentLink = () => {
        dispatch(deleteRequest({links: [closestLine.current.id]}))
        
        if (clonedGraphData.nodes.find(node => node.id === closestLine.current.dataset.targetId).status === 2) {
            const childNodes = getConnected(closestLine.current.dataset.targetId, false, true, false, 0);
            const isBlocked = childNodes.some(child => child.id !== closestLine.current.dataset.sourceId && child.status !== 3);

            if (!isBlocked) dispatch(updateNodesRequest([{ id: closestLine.current.dataset.targetId, status: 0 }]));
        }
            
        setTimeout(() => {closestLine.current.remove()}, 100);
        needSimulation.current = true;
    }

    const changeCurrentLinkType = () => {
        const newType = parseInt(closestLine.current?.dataset.type) ? 0 : 1;
        dispatch(updateLinkRequest({ id: closestLine.current?.id, type: newType }))

        const targetNode = clonedGraphData.nodes.find(node => node.id === closestLine.current.dataset.targetId);
        if ((targetNode.status === 2 && newType === 1) || (targetNode.status !== 2 && newType === 0)) {
            let childNodes = getConnected(targetNode.id, false, true, false, 0);

            if (newType) childNodes = childNodes.filter(node => node.id !== closestLine.current.dataset.sourceId);
            else childNodes.push(clonedGraphData.nodes.find(node => node.id === closestLine.current.dataset.sourceId));

            const isBlocked = childNodes.some(child => child.status !== 3);
            dispatch(updateNodesRequest([{ id: targetNode.id, status: isBlocked ? 2 : 0 }]));
        }
    }

    return (
        <div className='neuro-space' id='neuro-space'
            ref={neuroRef}
            onMouseMove={handleOnMouseMove}
        >
            <div
                ref={lineButtonRef}
                className="absolute rounded-full text-white line-button"
                style={{
                    zIndex: 1,
                    transform: 'translate(-50%, -50%)',
                    opacity: '0',
                    transition: 'opacity 0.2s ease-out',
                    boxShadow: '0 0 5px 0px rgba(255, 255, 255, 0.1)',
                    display: 'flex',
                    alignItems: 'center', // Вирівнювання по вертикалі
                    justifyContent: 'center', // Вирівнювання по горизонталі
                    cursor: 'pointer',
                    //aspectRatio: '1',
                    //padding: '5px',
                }}
                onClick={() => {
                    if (lineButtonRef.current.classList.contains('pinned')) {
                        lineDeleteButtonRef.current.classList.remove('bounce-in');
                        lineTypeButtonRef.current.classList.remove('bounce-in');
                        lineAddNodeButtonRef.current.classList.remove('bounce-in');
                        lineButtonRef.current.classList.remove('pinned');

                    } else {
                        lineDeleteButtonRef.current.classList.add('bounce-in');
                        lineTypeButtonRef.current.classList.add('bounce-in');
                        lineAddNodeButtonRef.current.classList.add('bounce-in');
                        lineButtonRef.current.classList.add('pinned');
                    }
                }}
            >
                <i className="fas fa-wrench"
                    style={{
                        opacity: '0.2',
                    }}
                />
                <button
                    title="Delete link"
                    ref={lineDeleteButtonRef}
                    className="absolute rounded-full bg-black text-white line-delete-button"
                    onClick={() => deleteCurrentLink()}
                >
                    <i className="fas fa-scissors" style={{ color: '#ff2222' }}/>
                </button>
                <button
                    title="Split link with new node"
                    ref={lineAddNodeButtonRef}
                    className="absolute rounded-full bg-black text-white line-addnode-button"
                    onClick={() => splitCurrentLinkAddNode()}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="70%" height="70%" fill="none" viewBox="0 0 100 100">
                        <path stroke="#fff" strokeLinecap="round" strokeWidth="10" d="M35 50h30M50 35v30"/>
                        <path fill="#fff" d="M1.464 91.465a5 5 0 1 0 7.072 7.07l-7.072-7.07Zm7.072 7.07 15-15-7.072-7.07-15 15 7.072 7.07ZM98.535 8.536a5 5 0 0 0-7.07-7.072l7.07 7.072Zm-7.07-7.072-15 15 7.07 7.072 15-15-7.07-7.072Z"/>
                        <circle cx="50" cy="50" r="31" stroke="#fff" strokeWidth="8"/>
                    </svg>


                </button>
                <button
                    title={`Change type to ${parseInt(closestLine.current?.dataset.type) ? "secondary" : "primary"}`}
                    ref={lineTypeButtonRef}
                    className="absolute rounded-full bg-black text-white line-type-button"
                    onClick={() => changeCurrentLinkType()}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="70%" height="70%" fill="none" viewBox="0 0 100 100">
                        <path stroke="#fff" strokeLinecap="round" strokeWidth="10" d="M5 80 85 5M50 65l15-15m15-15 15-15M20 95l15-15"/>
                    </svg>
                </button>
            </div>
            <button
                className="absolute rounded-full text-white add-node-button"
                onClick={() => addNewNode()}
            >
                <i className="fas fa-plus" 
                />
            </button>
            <button
                className="absolute rounded-full text-white fit-zoom-button"
                onClick={() => fgRef.current.zoomToFit(300)}
            >
                <i className="fas fa-crosshairs"
                />
            </button>
            <button
                className="absolute rounded-full text-white settings-button"
                onClick={() => console.log('Settings button clicked')}
            >
                <i className="fas fa-gear"
                />
            </button>
            <button
                className="absolute rounded-full text-white logout-button"
                onClick={() => {
                    console.log('logout button clicked');
                    dispatch(logout());
                    navigate('/');
                }}
            >
                <i className="fa-solid fa-right-from-bracket"></i>
            </button>
            <button
                className="absolute rounded-full text-white trash-button"
                onClick={() => console.log('Trash button clicked')}
            >
                <i className="fas fa-trash"
                />
            </button>
            <svg id="new-connection-container" width="100%" height="100%" style={{ position: 'absolute', top: 0, left: 0, zIndex: 999, pointerEvents: 'none' }}>
                <line
                    ref={newConnectionLineRef}
                    id="drag-line"
                    x1="-1"
                    y1="-1"
                    x2="-1"
                    y2="-1"
                    className="new-connection-line"
                />
            </svg>
            {clonedGraphData?.nodes?.length > 0 && (<>
                <Graph3D
                    graphData={clonedGraphData}
                    fgRef={fgRef}
                    newConnectionLineRef={newConnectionLineRef}
                    focusOnNode={focusOnNode}
                    focusNodeId={focusNodeId}
                    getConnected={getConnected}
                    lineButtonRef={lineButtonRef}
                    needSimulation={needSimulation}
                    neuroRef={neuroRef}
                    blockAllTargets={blockAllTargets}
                />

                <button
                    ref={stackButtonRef}
                    className={`absolute rounded-full text-white stack-button ${stackExpanded ? 'expanded' : 'collapsed'}`}
                    onClick={() => { console.log('stack button clicked'); stackExpand();}}
                >
                    <i className={`fas fa-tasks-alt`}
                    />
                </button>
                <div
                    ref={stackRef}
                    className={`absolute stack-container ${stackExpanded ? 'expanded' : 'collapsed'}`}
                >
                    <div className={`cards-stack ${stackExpanded ? 'expanded' : 'collapsed'}`}>
                        <SimpleBar
                            className={`cards-stack-scrollable-container ${stackExpanded ? 'expanded' : 'collapsed'}`}
                            style={{ maxHeight: '98%', minHeight: '98%', paddingBottom: 60 }}>
                            {clonedGraphData.nodes.map((node) => (
                                <Provider key={node.id} store={store}>
                                    <NeonGradientCard
                                        node={node}
                                        focusOnNode={focusOnNode}
                                        inStack={true}
                                        getConnected={getConnected}
                                        blockAllTargets={blockAllTargets}
                                    />
                                </Provider>
                            ))}
                        </SimpleBar>
                    </div>
                    <button
                        ref={stackButtonRef}
                        className={`stack-close-btn ${stackExpanded ? 'expanded' : 'collapsed'}`}
                        onClick={() => { console.log('stack button clicked'); stackExpand();}}
                    >
                        <i className={`fas fa-caret-down`}
                        />
                    </button>
                </div>
            </>)}
        </div>
    );
});

export default NeuroSpace;
