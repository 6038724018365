import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  graphData: {
    nodes: [],
    links: []
  },
  syncQueue: JSON.parse(localStorage.getItem('syncQueue')) || [],
  isOffline: !navigator.onLine,
};

const graphSlice = createSlice({
  name: 'graph',
  initialState,
  reducers: {
    setGraphData(state, action) {
      state.graphData = action.payload;
    },
    nodeRemoteUpdate(state, action) {},
    linkRemoteUpdate(state, action) {},
    updateNodesRequest(state, action) {
      state.graphData.nodes = state.graphData.nodes.map(node => {
        const update = action.payload.find(updatedNode => updatedNode.id === node.id);
        return update ? { ...node, ...update } : node;
      });
    },
    updateLinkRequest(state, action) {
      state.graphData.links = state.graphData.links.map(link => {
        return action.payload.id === link.id ? { ...link, ...action.payload } : link;
      });
    },
    enqueueChange(state, action) {
      const { id, changes, type } = action.payload;
      const existingItem = state.syncQueue.find(item => item.id === id && item.type === type);

      if (existingItem) {
        existingItem.changes = { ...existingItem.changes, ...changes };
      } else {
        state.syncQueue.push({ id, changes, type });
      }
      localStorage.setItem('syncQueue', JSON.stringify(state.syncQueue));
    },
    clearSyncQueue(state) {
      state.syncQueue = [];
      localStorage.removeItem('syncQueue');
    },
    setOffline(state, action) {
      state.isOffline = action.payload;
    },
    addNodeRequest(state, action) {
      const newNode = {
        id: action.payload.id,
        status: 0,
        volume: 4,
        ...action.payload,
      };
      state.graphData.nodes.push(newNode);
    },
    addLinkRequest(state, action) {
      const newLink = {
        id: action.payload.id,
        type: action.payload?.type || 0,
        ...action.payload,
      };
      state.graphData.links.push(newLink);
    },
    deleteRequest(state, action) {
      const { nodes, links } = action.payload;
      if (nodes) state.graphData.nodes = state.graphData.nodes.filter(node => !nodes.includes(node.id));
      if (links) state.graphData.links = state.graphData.links.filter(link => !links.includes(link.id));
    },
    updateCoordinatesRequest(state, action) {
      const { nodes } = action.payload;

      nodes.forEach(newNode => {
        // Знайти існуючий вузол за ID і оновити його координати
        const existingNode = state.graphData.nodes.find(node => node.id === newNode.id);
        if (existingNode) {
          existingNode.x = newNode.x;
          existingNode.y = newNode.y;
          existingNode.z = newNode.z;
        }
      });
    },
    turnOnLoading(state) {
      state.loading = true;
    },
    turnOffLoading(state) {
      state.loading = false;
    },
  },
});

export const {
  setGraphData,
  nodeRemoteUpdate,
  linkRemoteUpdate,
  updateNodesRequest,
  updateLinkRequest,
  deleteRequest,
  enqueueChange,
  clearSyncQueue,
  setOffline,
  addNodeRequest,
  addLinkRequest,
  turnOnLoading,
  turnOffLoading,
  updateCoordinates,
  updateCoordinatesRequest,
} = graphSlice.actions;

export default graphSlice.reducer;
