import React from 'react';
import ReactDOM from 'react-dom/client';
import 'typeface-roboto';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';
import Loader from 'components/layouts/Loader';
import '@fortawesome/fontawesome-free/css/all.min.css';


// styles
import './index.css';
import './styles/login.css';
import './styles/animations.css';
import './styles/general.css';
import './styles/layouts.css';
import './styles/card.css';
import './styles/neuro_space.css';

// Get the root element
const rootElement = document.getElementById('root');

// Create a root using React 18's createRoot
const root = ReactDOM.createRoot(rootElement);

// Render the app
root.render(
  <Router>
      <Provider store={store}>
        <Loader />
        <App />
    </Provider>
  </Router>
);
