export const STATUS_COLORS = {
    0: { firstColor: "#ffe600", secondColor: "#9dff00" }, // Може бути виконана
    1: { firstColor: "#00bbff", secondColor: "#00ff66" }, // Виконується
    2: { firstColor: "#cc0000", secondColor: "#ff6600" }, // Заблокована
    3: { firstColor: "#f700ff", secondColor: "#008cff" }, // Виконана
    // 3: { firstColor: "#fff", secondColor: "#ff0000" }, // Виконана
};

export const STATUS_OPTIONS = {
        0: "Available",
        1: "In Progress",
        2: "Blocked",
        3: "Completed",
};


export const DATE_OPTIONS = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false };

export const IS_MOBILE = window.innerWidth < 768;
  
export const LINE_STATUS_COLORS = {
    0: "#cc990033", 
    1: "#00993333", 
    2: "#ff660033", 
    3: "#5522ff33", 
    null: '#808080',
};
  
export const NODE_STATUS_COLORS = {
    0: "#996600fe", 
    1: "#009933fe", 
    2: "#991911fe", 
    3: "#4444bbfe", 
    null: '#808080',
};
  
export const FAR_STATUS_SECONDARY_COLOR = {
    0: "#968300f0", 
    1: "#00568ff0", 
    2: "#913a00f0", 
    3: "#008f87f0", 
    null: '#808080',
};

export const TYPE_TEXT = {
    0: "Dream",
    1: "Global Goal",
    2: "Process",
    3: "Task",
    4: "Skill",
    5: "Tool",
    6: "Technology",
    7: "Resource",
    8: "Resource Source",
    null: "Undefined",
}

export const NODE_PROPS_TO_UPDATE = [
    "asignee",
    "attachments",
    "description",
    "due_date",
    "last_edited_time",
    "priority",
    "status",
    "tags",
    "title",
    "type",
    "volume",
];

export const LINK_PROPS_TO_UPDATE = ["type"];
