export function SeededRandom(seed) {
    return Math.random();
}

export const isAuthenticated = () => {
    return !!localStorage.getItem('token');
};

export const debounce = (func, delay) => {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func(...args);
        }, delay);
    };
};